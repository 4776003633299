'use client';

import { StoreBranchApi } from '@/api-client/store-branch';
import { LOCAL_LOCATION } from '@/constants';
import { cn } from '@/lib/utils';
import useLocationStore from '@/store/locationStore';
import { resetLocalStepBooking } from '@/utils/global-func';
import { CheckCircleFilled, DownOutlined } from '@ant-design/icons';
import { IconMapPinFilled } from '@tabler/icons-react';
import { Input, MenuProps, Modal } from 'antd';
import clsx from 'clsx';
import { SearchIcon } from 'lucide-react';
import Image from 'next/image';
import React, { useEffect, useMemo, useState } from 'react';

interface ILocationDropdownProps {
  className?: {
    root?: string;
    text?: string;
    colorLocationICon?: string;
  };
  fillIcon?: string;
  icon?: string;
}

const LocationDropdown = (props: ILocationDropdownProps) => {
  const { className, fillIcon = '#1d3f75', icon } = props;
  const [branches, setBranches] = useState<any[]>([]); // Initialize state with empty array
  const [selectedBranch, setSelectedBranch] = useState<any>(null); // Set initial selected branch
  const [modalOpen, setModalOpen] = React.useState(false);
  const [modalOpenV2, setModalOpenV2] = React.useState(false);
  const { location: locationStore } = useLocationStore();
  const [citySelect, setCitySelect] = useState<string>('');
  const [textSearchLocation, setTextSearchLocation] = useState<string>('');

  useEffect(() => {
    if (locationStore?.name) {
      setSelectedBranch(() => {
        return {
          ...locationStore,
          _id: locationStore.id,
        };
      });
    }
  }, [locationStore]);
  //  list city group by  city
  const groupByCity = useMemo(() => {
    return branches.reduce((acc, item) => {
      // 8990 Coit Rd, Ste 300, Frisco, TX 75035
      // city lấy vị trí Frisco của address
      const address = item.address.split(',');
      let city = address?.[address.length - 2]?.trim() || 'other';
      city = city.toLowerCase();
      if (!acc[city]) {
        acc[city] = [];
      }
      acc[city].push(item);
      return acc;
    }, {});
  }, [branches]);

  const locationsShowSearch = useMemo(() => {
    const searchText = textSearchLocation?.trim().toLowerCase(); // Chuẩn hóa từ khóa tìm kiếm

    if (!searchText) {
      return groupByCity[citySelect] || []; // Trả về danh sách theo city nếu không tìm kiếm
    }

    return branches.filter(
      ({ name, address }) => name.toLowerCase().includes(searchText) || address.toLowerCase().includes(searchText),
    );
  }, [citySelect, branches, textSearchLocation]);
  useEffect(() => {
    if (selectedBranch?.address) {
      const address = selectedBranch.address.split(',');
      let city = address?.[address.length - 2]?.trim() || 'other';
      setCitySelect(city.toLowerCase());
    }
  }, [selectedBranch]);

  const handleStorageChange = (e: StorageEvent) => {
    if (e.key === 'location_id') {
      // If location_id changes, update the selectedBranch
      const updatedLocationId = localStorage.getItem('location_id');
      if (updatedLocationId) {
        const updatedBranch = branches.find(branch => branch._id === updatedLocationId);
        if (updatedBranch) {
          setSelectedBranch(updatedBranch);
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener('storage', handleStorageChange);
    const fetchBranches = async () => {
      try {
        // Fetch branches from API
        const response = await StoreBranchApi.getAll();
        const branchesData = response ?? []; // Fallback to empty array if response is null or undefined
        setBranches(branchesData?.filter((item: any) => item.opened_at !== null));

        // Check if a location is saved in localStorage
        const savedLocationId = LOCAL_LOCATION;

        if (savedLocationId) {
          // If user has selected a location before, load it
          const savedBranch = branchesData.find(branch => branch._id === savedLocationId);
          if (savedBranch) {
            setSelectedBranch(savedBranch);
          }
        } else {
          // If no saved location, calculate the nearest branch
          const { latitude, longitude } = await getBrowserLocation();

          const nearestBranch = branchesData.reduce((nearest, branch) => {
            const branchCoords = getBranchCoordinates(branch.name);
            const distanceToBranch = calculateDistance(latitude, longitude, branchCoords.lat, branchCoords.lng);

            if (!nearest || distanceToBranch < nearest.distance) {
              return { branch, distance: distanceToBranch };
            }

            return nearest;
          }, null);

          if (nearestBranch) {
            resetLocalStepBooking(); // Reset booking steps
            localStorage.setItem('location_id', nearestBranch.branch._id); // Store nearest branch ID in localStorage
            setSelectedBranch(nearestBranch.branch); // Set nearest branch as selected
          }
        }
      } catch (error) {
        console.error('Error fetching branches or user location:', error);
      }
    };

    fetchBranches();

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  // Return loading state while fetching to avoid mismatch
  if (!branches.length) return null; // Avoid rendering mismatched content

  const getBrowserLocation = () => {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => {
            const { latitude, longitude } = position.coords;
            resolve({ latitude, longitude });
          },
          error => {
            reject(error);
          },
        );
      } else {
        reject(new Error('Geolocation is not supported by this browser.'));
      }
    });
  };

  const getBranchCoordinates = branchName => {
    const coordinates = {
      'Flower Mound': { lat: 33.00556, lng: -97.06995 },
      Mansfield: { lat: 32.5632, lng: -97.1417 },
      Frisco: { lat: 33.1507, lng: -96.8236 },
    };
    return coordinates[branchName];
  };

  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const toRad = x => (x * Math.PI) / 180;
    const R = 6371; // Radius of the earth in km
    const dLat = toRad(lat2 - lat1);
    const dLon = toRad(lon1 - lon2);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
  };

  // Create dropdown menu items with branch names
  const items: MenuProps['items'] = branches.map(branch => ({
    key: branch._id,
    label: (
      <div>
        <strong>{branch.name}</strong>
        <div className='text-xs text-gray-500'>{branch.address}</div>
      </div>
    ),
    onClick: () => {
      setSelectedBranch(branch); // Set selected branch on click
      resetLocalStepBooking(); // Reset booking steps
      localStorage.setItem('location_id', branch._id); // Store selected branch ID in localStorage
      window.location.reload(); // Reload the page to reflect changes
    },
  }));

  // console.log(
  //   'branches',
  //   branches.filter(item => item.opened_at !== null),
  // );

  const handleChangeLocation = (branchSet: any) => {
    setSelectedBranch(branchSet); // Set selected branch on click
    resetLocalStepBooking(); // Reset booking steps
    localStorage.setItem('location_id', branchSet._id); // Store selected branch ID in localStorage
    setModalOpenV2(false);
    window.location.reload(); // Reload the page to reflect changes
  };

  // handle close modal
  const handleCloseModal = () => {
    setModalOpenV2(false);
    setTextSearchLocation('');
    if (selectedBranch?.address) {
      const address = selectedBranch.address.split(',');
      let city = address?.[address.length - 2]?.trim() || 'other';
      setCitySelect(city.toLowerCase());
    }
  };
  const handleChangeCity = (city: string) => {
    setCitySelect(city);
    const locations = groupByCity[city];
    if (locations?.length === 1) {
      handleChangeLocation(locations[0]);
    }
  };

  return (
    <div className={clsx('flex w-full items-center justify-end bg-zelene-blue', className?.root)}>
      <div className='flex items-center gap-2'>
        {/* <span className={clsx('text-sm text-white', className?.text)}>Location:</span> */}

        <Modal
          classNames={{
            body: 'flex flex-col gap-2',
            content: '!border !bg-[#FFF4E4]',
            header: '!bg-[#FFF4E4]',
          }}
          centered
          closable={false}
          footer={[]}
          open={!selectedBranch || modalOpen}
          title={<div className='font-prettywise !text-[28px] font-medium text-zelene-dark-blue'>Store near you</div>}
        >
          {branches?.map((branch, index) => (
            <div
              key={index}
              className='flex cursor-pointer justify-center rounded-xl bg-gray-50 p-4 first:mt-6'
              onClick={() => {
                setSelectedBranch(branch); // Set selected branch on click
                localStorage.setItem('location_id', branch._id); // Store selected branch ID in localStorage
                resetLocalStepBooking(); // Reset booking steps
                window.location.reload(); // Reload the page to reflect changes
              }}
            >
              <div className='flex flex-col justify-center'>
                <strong className='text-center font-hneu text-[17px] font-medium text-zelene-blue'>
                  {branch.name}
                </strong>
                <div className='font-hneu text-sm text-zelene-grey'>{branch.address}</div>
              </div>
            </div>
          ))}
        </Modal>
        <Modal
          footer={[]}
          centered
          title={
            <div className='flex w-full justify-start'>
              <div className="font-['Helvetica Neue'] text-base font-medium leading-tight text-[#1d3f75]">
                Choose Location
              </div>
            </div>
          }
          open={modalOpenV2}
          onClose={() => {
            handleCloseModal();
          }}
          onCancel={() => {
            handleCloseModal();
          }}
          width={400}
          styles={{
            content: {
              padding: '12px 16px',
            },
          }}
        >
          {/* search input */}
          <div className='mt-4 flex flex-col gap-4'>
            <div>
              <Input
                size='middle'
                placeholder='Search location'
                prefix={<SearchIcon size={16} color='rgba(29, 63, 117, 1)' />}
                value={textSearchLocation}
                onChange={e => setTextSearchLocation(e.target.value)}
              />
            </div>
            {!textSearchLocation?.trim() && (
              <div className='flex flex-col gap-4'>
                <div className="font-['Helvetica Neue'] text-xs font-normal text-[#a7a7a7]">
                  Select your region or state for better service
                </div>
                <div className='grid grid-cols-2 gap-2'>
                  {Object.keys(groupByCity).map((city, index) => {
                    const isActived = city === citySelect;
                    return (
                      <div
                        key={index}
                        className={cn(
                          "font-['Helvetica Neue'] inline-flex cursor-pointer items-center justify-between rounded-md px-2 py-1 text-sm font-medium capitalize",
                          isActived ? 'bg-zelene-blue text-white' : 'text-[#1d3f75] hover:bg-[#F2F2F2]',
                        )}
                        onClick={() => {
                          handleChangeCity(city);
                        }}
                      >
                        <span>{city}</span>
                        {isActived && <CheckCircleFilled size={16} />}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {/* branch list */}
            {citySelect && (
              <div>
                <div className="font-['Helvetica Neue'] text-sm font-medium text-[#b84e44]">Shop location</div>
                <div className='mt-3 flex flex-col gap-2'>
                  {locationsShowSearch?.map((branch: any, index: number) => {
                    const isActived = selectedBranch?._id === branch._id;
                    return (
                      <div
                        key={index}
                        className={cn(
                          'inline-flex cursor-pointer items-center justify-start gap-3 rounded-md px-2 py-1',
                          isActived
                            ? 'bg-zelene-blue text-white'
                            : 'text-[#1d3f75] text-zelene-blue-secon hover:bg-[#F2F2F2]',
                        )}
                        onClick={() => {
                          handleChangeLocation(branch);
                        }}
                      >
                        <IconMapPinFilled size={16} />
                        <div className="font-['Helvetica Neue'] font-['Helvetica Neue'] text-sm leading-[17px]">
                          <div className='font-medium'>{branch?.name}</div>
                          <div className='text-xs font-normal'>{branch?.address}</div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </Modal>
        {/* Location Dropdown */}
        {/* <Dropdown menu={{ items }} trigger={['click']} placement='bottom'>
         
        </Dropdown> */}
        <span
          className='flex cursor-pointer items-center text-sm font-medium text-white'
          onClick={() => setModalOpenV2(true)}
        >
          {selectedBranch ? (
            <div className='group flex cursor-pointer flex-col rounded-2xl border border-[#fcdbab] bg-white/50 px-3 py-1 sm:border-none sm:bg-inherit sm:py-0'>
              <div className='flex items-center justify-between gap-1 font-hneu text-zelene-grey *:text-[12px] group-hover:text-zelene-red'>
                <span>Current location</span>
                <DownOutlined className={clsx('color-[#102647] sm:color:black', className?.colorLocationICon)} />
              </div>

              <div
                className={clsx(
                  'flex items-start gap-2 font-hneu font-semibold text-zelene-blue *:text-sm *:text-white',
                  className?.text,
                )}
              >
                <Image
                  src={className?.colorLocationICon ? '/svgs/map-pin-blue.svg' : icon || '/svgs/map-pin-white.svg'}
                  alt='form logo'
                  width={16}
                  height={16}
                  className='size-4 object-contain'
                />

                <div className={clsx('!text-white group-hover:!text-zelene-red', className?.colorLocationICon)}>
                  {selectedBranch.name}
                </div>
              </div>
            </div>
          ) : (
            <div className='flex flex-col justify-center gap-2'>
              Select a location
              <DownOutlined className='ml-2' />
            </div>
          )}
          {/* <DownOutlined className='ml-2' /> */}
        </span>
      </div>
    </div>
  );
};

export default LocationDropdown;
